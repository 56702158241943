var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "previewPrint" }, [
    _c(
      "div",
      {
        staticStyle: {
          position: "relative",
          "margin-right": "30px",
          display: "flex",
          "justify-content": "space-between",
          cursor: "pointer",
        },
        attrs: { slot: "header" },
        slot: "header",
      },
      [
        _c("Button", { attrs: { type: "text" } }, [_vm._v("取消")]),
        _c(
          "Button",
          {
            attrs: { type: "primary", loading: _vm.btnLoading },
            on: { click: _vm.handelSubmit },
          },
          [_vm._v("审核")]
        ),
      ],
      1
    ),
    _c("div"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }